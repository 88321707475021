function commonFadeUp(): void {
  const scrollFadeUp = document.querySelectorAll(
    ".js-scrollFadeUp, [class*='p-top__category-item'] .sysItemBlock, .p-topics, .p-recruit"
  );
  const scrollFadeUpArray = Array.prototype.slice.call(scrollFadeUp);

  // OPTIONS
  // ------------------------------
  const options = {
    root: null,
    rootMargin: "-10% 0px",
    threshold: 0
  };

  const observer = new IntersectionObserver(scrollItemClass, options);

  scrollFadeUpArray.forEach(item => {
    observer.observe(item);
  });

  function scrollItemClass(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const elem = entry.target;
        elem.classList.add("is-show");
      }
    });
  }
}

export { commonFadeUp };
