function commonAddClassAnnounceBar(): void {
  const announcementsElement = document.querySelectorAll(".p-message li");
  const announcementsElementArr = Array.prototype.slice.call(
    announcementsElement
  );
  announcementsElementArr.forEach((el, index) => {
    if (index === 0) {
      el.classList.add("announcement--active");
    } else {
      el.classList.add("announcement--inactive");
    }
  });
}

function commonRoopAnnounce(): void {
  const announcementsElement = document.querySelectorAll(".p-message li");
  const announcementsElementArr = Array.prototype.slice.call(
    announcementsElement
  );
  const announceNumber = announcementsElementArr.length;
  let intervalId;
  let currentAnnouncement = 0;

  function commonRoopAnnounceInterval(): void {
    intervalId = window.setInterval(() => {
      setCurrentAnnouncement(currentAnnouncement + 1);
    }, 3000);
  }

  function setCurrentAnnouncement(newIndex): void {
    currentAnnouncement = newIndex % announceNumber;
    announcementsElementArr.forEach((el, index) => {
      if (index !== currentAnnouncement) {
        el.classList.add("announcement--inactive");
        el.classList.remove("announcement--active");
      } else {
        el.classList.remove("announcement--inactive");
        el.classList.add("announcement--active");
      }
    });
  }
  const announceBar = document.querySelectorAll(".p-message");
  const announceBarArray = Array.prototype.slice.call(announceBar);
  const body = document.body;

  // OPTIONS
  // ------------------------------
  const options = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0
  };

  const observer = new IntersectionObserver(scrollItemClass, options);

  if (announcementsElementArr.length > 0) {
    announceBarArray.forEach(item => {
      observer.observe(item);
    });
  }

  function scrollItemClass(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        body.classList?.remove("is-fixed");
        if (announceNumber > 1) {
          commonRoopAnnounceInterval();
        }
      } else {
        body.classList?.add("is-fixed");
        if (announceNumber > 1) {
          clearInterval(intervalId);
        }
      }
    });
  }
}

export { commonRoopAnnounce, commonAddClassAnnounceBar };
