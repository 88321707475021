/**
 * エントリーポイントファイル
 */

// 主要ライブラリインポート例
// import * as $ from "jquery";

// web-vitalsインポート
import { getCLS, getFID, getLCP } from "web-vitals";

// ベースファイル
// Polyfill ポリフィル
import "./polyfill";
// Lazy Load 画像遅延読み込み
import "lazysizes";

// Namespace（プロジェクト開始時には以下ファイルを適宜削除もしくは移動させて利用してください）
// Commonインポート
import { commonSmoothScroll } from "./namespace/common/commonSmoothScroll";
import { commonFadeUp } from "./namespace/common/commonFadeUp";
import { commonInsertSpanToPrice } from "./namespace/common/commonInsertSpanToPrice";
import { commonNewsContentWrap } from "./namespace/common/commonNewsContentWrap";
import { commonBannerImgInsertWrap } from "./namespace/common/commonBannerImgInsertWrap";
import {
  commonTrimProductName,
  trimNameMediaQuery
} from "./namespace/common/commonTrimProductName";
import { commonRoopAnnounce } from "./namespace/common/commonAnnounceBar";
import { commonAddClassAnnounceBar } from "./namespace/common/commonAnnounceBar";

// Topインポート
import {
  topMvSlider,
  topProductSlider,
  topMediaQuery,
  categoryLayout,
  prLayout,
  adjustHeightPrArea,
  productsLayout
} from "./namespace/top";
// Category
import { categoryPrLayout } from "./namespace/category";
// detail
import {
  productSlider,
  sliderItemAddClassName,
  moveThumbnailSliderElement,
  addClassSelectElement,
  checkRecommendItem,
  insertSpanToPrice
} from "./namespace/detail";
// モーダルインポート
import { MicoModal } from "./namespace/modal";
// Common処理実行
commonSmoothScroll();
commonFadeUp();
commonNewsContentWrap();
prLayout();
commonBannerImgInsertWrap();
commonAddClassAnnounceBar();
commonRoopAnnounce();

// Top処理実行
if (document.body.id === "top") {
  if (window.matchMedia && window.matchMedia("(min-width: 1100px)").matches) {
    commonTrimProductName(".p-top__category-item .sysItemName a", 27);
  } else if (
    window.matchMedia &&
    window.matchMedia("(min-width: 768px)").matches
  ) {
    commonTrimProductName(".p-top__category-item .sysItemName a", 15);
  }
  if (window.matchMedia && window.matchMedia("(min-width: 768px)").matches) {
    topMvSlider(".p-top__mv-pc ul", ".p-top__mv-progress-pc", false, 767);
    topProductSlider();
  }
  if (
    window.matchMedia &&
    window.matchMedia("not all and (min-width: 768px)").matches
  ) {
    //SP表示
    topMvSlider(".p-top__mv-sp ul", ".p-top__mv-progress-sp", true, 768);
    commonTrimProductName(".p-top__category-item .sysItemName a", 27);
    // saiziInfoLinkLayoutSP();
  }
  trimNameMediaQuery(".p-top__category-item");
  categoryLayout();
  topMediaQuery();
  commonInsertSpanToPrice(".sysItemPrice");
  // commonTrimProductName(".sysItemName a", 23);
  adjustHeightPrArea(".p-top__category-item .sysItemPrArea");
  adjustHeightPrArea(".p-top__category-item--02 .sysItemPrArea");
  productsLayout();
}

if (document.body.id === "sysItemCategory") {
  categoryPrLayout();
  // commonTrimProductName(".sysItemName", 18);
  commonInsertSpanToPrice(".sysRetailPrice");
  commonInsertSpanToPrice(".sysYamiPrice");
}

if (document.body.id === "sysItemDetail") {
  sliderItemAddClassName();
  moveThumbnailSliderElement();
  productSlider();
  insertSpanToPrice();
  // commonInsertSpanToPrice(".sysRetailPrice");
  addClassSelectElement();
  commonInsertSpanToPrice(".recommend-item .sysItemPrice");
  commonInsertSpanToPrice(".recentry-item .sysItemPrice");
  commonTrimProductName(".recommend-item .sysItemName a", 18);
  commonTrimProductName(".recentry-item .sysItemName a", 18);
  checkRecommendItem();
}

if (document.body.id === "sysItemSearch") {
  commonTrimProductName(".sysItemName a", 18);
  commonInsertSpanToPrice(".sysRetailPrice");
}

// モーダル処理実行
MicoModal();
