import * as $ from "jquery";
import "slick-carousel";
import { getMediaQueryList } from "../utils/getMediaQueryList";

function topMvSlider(target, progressTarget, option, width): void {
  let tick;
  let percentTime = 0;
  const progressElement = progressTarget;
  const time = 4;
  const $slick = $(target);
  const targetSlideCount = document.querySelectorAll(`${target} li`).length;

  function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    tick = setInterval(interval, 10);
  }
  function interval() {
    percentTime += 1 / (time + 0.1);
    $(progressElement + " .p-slider-nav__progress").css({
      width: percentTime + "%"
    });
    if (percentTime >= 100) {
      $slick.slick("slickNext");
      startProgressbar();
    }
  }
  function resetProgressbar() {
    $(progressElement + " .p-slider-nav__progress").css({
      width: 0 + "%"
    });
    clearTimeout(tick);
  }
  function stopProgressbar() {
    clearTimeout(tick);
  }
  function mediaQuery(): void {
    getMediaQueryList("md").addEventListener("change", event => {
      if (event.matches) {
        stopProgressbar();
      } else {
        stopProgressbar();
      }
    });
  }

  if (targetSlideCount > 1) {
    startProgressbar();
    mediaQuery();
  } else {
    $(progressElement)?.addClass("is-hide");
  }

  $slick
    .on("init", function(event, slick) {
      $(progressElement + " .p-slider-nav__current-num").text(
        slick.currentSlide + 1
      );
      $(progressElement + " .p-slider-nav__total-num").text(slick.slideCount);
    })
    .slick({
      mobileFirst: option,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      speed: 1200,
      dots: false,
      centerMode: true,
      prevArrow:
        '<button class="p-slider-nav__prev-button" aria-label="前の画像へ移動"><svg class="p-slider-nav__arrow-img" role="img"><title>左矢印アイコン</title><use href="/img/common/sprite.svg#icon_arrow_left"></use></svg></button>',
      nextArrow:
        '<button class="p-slider-nav__next-button" aria-label="次の画像へ移動"><svg class="p-slider-nav__arrow-img" role="img"><title>右矢印アイコン</title><use href="/img/common/sprite.svg#icon_arrow_right"></use></svg></button>',
      responsive: [
        {
          breakpoint: width,
          settings: "unslick"
        }
      ]
    })
    .on({
      beforeChange: function(event, slick, currentSlide, nextSlide) {
        if (slick.slideCount > 1) {
          resetProgressbar();
          startProgressbar();
          $(progressElement + " .p-slider-nav__progress").css({
            width: 100 + "%"
          });
          $(progressElement + " .p-slider-nav__current-num").text(
            nextSlide + 1
          );
        }
      }
    });
}

function topProductSlider(): void {
  const $slick = $(".p-top__category-item ul, .p-top__category-item--02 ul");
  $slick
    .on("init", function(event, slick) {
      if (slick.slideCount < 2) {
        $slick?.addClass("is-low-number");
      }
    })
    .slick({
      rows: 2,
      // centerMode: true,
      centerPadding: "5%",
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      prevArrow:
        '<button class="slide-arrow prev-arrow" aria-label="前の画像へ移動"></button>',
      nextArrow:
        '<button class="slide-arrow next-arrow" aria-label="次の画像へ移動"></button>',
      responsive: [
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]
    });
}

function categoryLayout(): void {
  const categoryContentBlock = $("[class*='p-top__category-body']");
  const categoryContentBlockArr = Array.prototype.slice.call(
    categoryContentBlock
  );
  const categoryBtnBlock = $("[class*='p-top__category-btn-area']");
  const categoryBtnBlockArr = Array.prototype.slice.call(categoryBtnBlock);

  categoryContentBlockArr?.forEach(element => {
    const parentElement = element.nextElementSibling.querySelector(
      ".sysContent"
    );
    const position = parentElement.firstChild;
    parentElement?.insertBefore(element, position);
  });

  categoryBtnBlockArr?.forEach(element => {
    const parentElement = element.previousElementSibling.querySelector(
      ".sysItemBlock"
    );

    parentElement?.appendChild(element);
  });
}

function prLayout(): void {
  const target = $("[class*='p-top__category-item'] .sysItemBlock li");
  const targetParentArr = Array.prototype.slice.call(target);
  targetParentArr?.forEach(element => {
    const target = element.querySelector(".sysItemPrArea");
    const position = element.querySelector(".sysItemImage");
    position?.after(target);
  });
}

function adjustHeightPrArea(targetContent): void {
  let maxHeight = 0;
  const prItems = document.querySelectorAll<HTMLElement>(targetContent);
  if (prItems) {
    // 一番高いコンテンツの高さを取得するためのループ
    for (let i = 0; i < prItems.length; i++) {
      // 変数に入っている高さを上回ったら上書きする
      if (prItems[i].clientHeight > maxHeight) {
        maxHeight = prItems[i].clientHeight;
      }
    }
    // 一番高い高さを反映するためのループ
    for (let i = 0; i < prItems.length; i++) {
      prItems[i].style.minHeight = maxHeight + "px";
    }
  }
}
// window = $(window);
//MVスライダーとProductスライダーの画面幅による制御
function topMediaQuery(): void {
  getMediaQueryList("md").addEventListener("change", event => {
    if (event.matches) {
      //PC表示になった時に実行:
      topProductSlider();
      topMvSlider(".p-top__mv-pc ul", ".p-top__mv-progress-pc", false, 768);
      // $(".p-top__mv-sp .slick-initialized").slick("unslick");
      $(".p-top__mv-sp")
        .not(".slick-initialized")
        .slick("unslick");
    } else {
      //SP表示になった時
      $(".p-top__category-item ul, .p-top__category-item--02 ul")
        .not(".slick-initialized")
        .slick("unslick");
      topMvSlider(".p-top__mv-sp ul", ".p-top__mv-progress-sp", true, 768);
      // $(".p-top__mv-pc .slick-initialized").slick("unslick");
      $(".p-top__mv-pc")
        .not(".slick-initialized")
        .slick("unslick");
    }
  });
}

function productsLayout(): void {
  const target = $(".p-products li");
  const targetParentArr = Array.prototype.slice.call(target);
  targetParentArr?.forEach(element => {
    const target = element.querySelector(".sysItemPrice");
    const position = element.querySelector(".sysItemName");
    position?.after(target);
  });
}

export {
  topMvSlider,
  topProductSlider,
  topMediaQuery,
  categoryLayout,
  prLayout,
  adjustHeightPrArea,
  productsLayout
};
