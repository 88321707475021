function commonInsertSpanToPrice(targetElement): void {
  const target = document.querySelectorAll(targetElement);
  const targetArr = Array.prototype.slice.call(target);
  targetArr.forEach(element => {
    const convertText = element.textContent.replace(
      /円（税込）/g,
      `<span>円（税込）</span>`
    );
    element.textContent = "";
    element.insertAdjacentHTML("afterbegin", convertText);
  });
}

export { commonInsertSpanToPrice };
