import { getMediaQueryList } from "../../utils/getMediaQueryList";
import { trimTxt } from "../../helper/trimTxt";

function commonTrimProductName(targetElement, trimNumber): void {
  const target = document.querySelectorAll(targetElement);
  const targetArr = Array.prototype.slice.call(target);
  targetArr.forEach(element => {
    const productName = element.textContent;
    const convertproductName = trimTxt(productName, trimNumber, "…");
    element.textContent = convertproductName;
  });
}

function trimNameMediaQuery(target): void {
  getMediaQueryList("lg").addEventListener("change", event => {
    if (event.matches) {
      commonTrimProductName(`${target} .sysItemName a`, 27);
    } else {
      commonTrimProductName(`${target} .sysItemName a`, 15);
    }
  });
}

export { commonTrimProductName, trimNameMediaQuery };
