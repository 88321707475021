import * as $ from "jquery";
import "slick-carousel";

function sliderItemAddClassName(): void {
  const target = document.querySelectorAll(".sysItemImages");
  const targetArr = Array.prototype.slice.call(target);
  targetArr.forEach((element, index) => {
    const target = element.querySelector(".sysImageListSimple");
    if (index === 0) {
      target?.classList.add("p-detail__main-slider");
    } else if (index === 1) {
      target?.classList.add("p-detail__thumbnail-slider");
    }
  });
}
function moveThumbnailSliderElement(): void {
  const target = document.querySelector(".p-detail__thumbnail-slider");
  const position = document.querySelector(".p-detail__main-slider");
  position?.parentNode.insertBefore(target, position.nextElementSibling);
}

function productSlider(): void {
  const slideCount = document.querySelector(".p-detail__thumbnail-slider")
    .childElementCount;
  // メイン画像のオプション
  $(".p-detail__main-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // 自動再生ON
    arrows: true, // 矢印非表示
    asNavFor: ".p-detail__thumbnail-slider", // サムネイルと同期
    prevArrow:
      '<button class="slick-prev slick-arrow" aria-label="前の画像へ移動"></button>',
    nextArrow:
      '<button class="slick-next slick-arrow" aria-label="次の画像へ移動"></button>'
  });
  // サムネイルのオプション
  $(".p-detail__thumbnail-slider").slick({
    slidesToShow: slideCount, // サムネイルの表示数
    asNavFor: ".p-detail__main-slider", // メイン画像と同期
    focusOnSelect: true // サムネイルクリックを有効化
  });
}

function addClassSelectElement(): void {
  const target = document.getElementById("sysSelections");
  const selectElement = target?.getElementsByTagName("select");
  if (selectElement?.length > 0) {
    target.classList.add("has-select");
  }
}

function checkRecommendItem(): void {
  const targetParent = document.querySelector<HTMLElement>(".recommend-item");
  const targetItem = targetParent?.getElementsByTagName("li");
  const checkItem = targetItem[0]?.dataset.itemId;
  if (checkItem) {
    targetParent.classList.add("has-item");
  }
}

// function checkRecentryItem(): void {
//   const targetParent = document.querySelector<HTMLElement>(
//     ".recommend-item, recentry-item"
//   );
//   const targetItem = targetParent?.getElementsByTagName("li");
//   const checkItem = targetItem[0]?.dataset.itemId;
//   if (checkItem) {
//     targetParent.classList.add("has-item");
//   }
// }

//価格の"円（税込）"をspanで囲む
function insertSpanToPrice(): void {
  const target = document.querySelector(".sysRetailPrice");

  // MutationObserverのコールバック関数
  const callback = (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        mutation.addedNodes.forEach(node => {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            node.matches(".sysRetailPriceValue")
          ) {
            // 変更を行う処理
            const convertText = node.textContent.replace(
              /円（税込）/g,
              `<span class="u-text-sm">円（税込）</span>`
            );
            node.innerHTML = convertText;

            // 監視を終了
            observer.disconnect();
          }
        });
      }
    }
  };

  // MutationObserverのインスタンスを作成
  const observer = new MutationObserver(callback);

  // 設定オブジェクト
  const config = { childList: true };

  // 監視を開始
  observer.observe(target, config);
}

export {
  sliderItemAddClassName,
  productSlider,
  moveThumbnailSliderElement,
  addClassSelectElement,
  checkRecommendItem,
  insertSpanToPrice
};
