/**
 * Micromodal.jsモーダル
 * https://micromodal.now.sh/#introduction
 */
import MicroModal from "micromodal";
function MicoModal(): void {
  // const pauseVideo = () => {
  //   document.querySelector("video").pause();
  // };
  MicroModal.init({
    disableScroll: true
  });
}

export { MicoModal };
