function categoryPrLayout(): void {
  const target = document.querySelectorAll(".sysItemPrArea");
  const targetArr = Array.prototype.slice.call(target);
  if (targetArr) {
    targetArr.forEach(element => {
      const position = element.parentNode.nextElementSibling;
      position?.after(element);
    });
  }
}

export { categoryPrLayout };
